import SEO from 'components/SEO'

export default function HomePage() {
  return (
    <>
      <SEO title="Home" />
      <h3 css="margin: 16px;">robertkirsz</h3>
    </>
  )
}
